import React, { useState } from 'react';

export default function PercentDropdown({ percents, id, name, onChange}) {
    const [selectedPercent, setSelectedPercent] = useState();

    const handlePercentChange = (event) => {
        setSelectedPercent(event.target.value);
        onChange(event);
    };

    return (
        <select
            id={id}
            name={name}
            value={selectedPercent}
            onChange={handlePercentChange}
            className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
        >
            {percents.map((percent) => (
                <option key={percent.id} value={percent.value}>
                    {percent.name}
                </option>
            ))}
        </select>
    );
};