import React, { useState } from 'react'
import { useForm } from '@tanstack/react-form'
import config from '../../config.json';
import numeral from 'numeral';
import utils from '../../utils/Utils'
import {
    Card,
    CardBody,
    Tooltip
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import PercentDropdown from "../../components/controls/PercentDropdown";

export default function StandardContent() {
    const { t } = useTranslation();
    const unit = '$';
    const percents = [
        { id: "percent-0", value: 0, name: '0%' },
        { id: "percent-1", value: 0.15, name: '15%' },
        { id: "percent-2", value: 0.12, name: '12%' }
    ];
    const [resultFees, setResultFees] = useState({
        revenue: 0,
        listingFee: 0,
        transactionFee: 0,
        paymentProcessingFee: 0,
        advertisingFee: 0,
        totalFee: 0,
        cost: 0,
        totalCost: 0,
        profit: 0,
        chart: {
            feePer: 0,
            costPer: 0,
            profitPer: 0
        }
    });
    const [chartConfig, setChartConfig] = useState({
        type: "pie",
        width: 260,
        height: 260,
        series: [],
        options: {
            chart: {
                id: 'basic-pie',
                toolbar: {
                    show: false,
                },
                background: '#fff'
            },
            title: {
                show: "",
            },
            labels: [t('profit'), t('esty_fees'), t('cost')],
            dataLabels: {
                enabled: true,
            },
            colors: ["#16a34a", "#f59e0b", "#a3a3a3"],
            legend: {
                show: false,
                position: 'bottom'
            },
        },
    });

    const form = useForm({
        defaultValues: {
            sellingPrice: '',
            cost: '',
            offsideAdsFeePercentage: config.offside_ads_fee_percentage,
            quantity: 1
        },
        onSubmit: async ({ value }) => {
            var result = calculateTotalFees(value.sellingPrice, value.cost, value.offsideAdsFeePercentage)
            var seriesData = [result.chart.profitPer, result.chart.feePer, result.chart.costPer];
            setChartConfig({
                type: "pie",
                width: 260,
                height: 260,
                series: seriesData,
                options: {
                    chart: {
                        id: 'basic-pie',
                        toolbar: {
                            show: false,
                        },
                    },
                    labels: [t('profit'), t('esty_fees'), t('cost')],
                    colors: ["#16a34a", "#f59e0b", "#a3a3a3"],
                    legend: {
                        show: false,
                        position: 'bottom'
                    },
                },
            });
            setResultFees(result);
        },
    })

    //default value quantity = 1
    const calculateTotalFees = (itemPrice, cost, offsideAdsFeePercentage, isPatternFee = false, isShippingFee = false,
        isRegulatoryFee = false, isSubscriptionFee = false, isVAT = false, vatPercent = config.vat_default, quantity = 1) => {
        var costCal = isNaN(cost) ? 0 : cost;
        var totalRevenue = quantity * itemPrice;
        var listingFee = config.listing_fee * quantity;
        var transactionFee = config.transaction_fee * totalRevenue;
        var paymentProcessingFee = config.payment_processing_fee * totalRevenue + config.payment_processing_fee_add_more;
        var advertisingFee = offsideAdsFeePercentage * totalRevenue;
        //optional
        var shippingFee = config.shipping_fee;
        var regulatoryFee = config.regulatory_fee;
        var subscriptionFee = config.subscription_fee;
        var patternFee = config.pattern_fee; // 15USD/month
        var vatDefault = config.vat_default;
        //var vatFee = vatPercent * totalRevenue;
        //total
        var totalFee = listingFee + transactionFee + paymentProcessingFee + advertisingFee;
        if (isPatternFee)
            totalFee += patternFee
        if (isShippingFee)
            totalFee += shippingFee
        if (isRegulatoryFee)
            totalFee += regulatoryFee
        if (isSubscriptionFee)
            totalFee += subscriptionFee
        if (isVAT)
            totalFee += vatDefault

        var totalCost = costCal + totalFee;
        var totalProfit = totalRevenue - totalCost;
        var chartFeePer = utils.roundNumber((totalFee) * 100 / totalRevenue, 1);
        var chartCostPer = utils.roundNumber(costCal * 100 / totalRevenue, 1);
        var chartProfitPer = utils.roundNumber(totalProfit * 100 / totalRevenue, 1);
        return {
            revenue: totalRevenue,
            listingFee: listingFee,
            transactionFee: transactionFee,
            paymentProcessingFee: paymentProcessingFee,
            advertisingFee: advertisingFee,
            totalFee: totalFee,
            cost: costCal,
            totalCost: totalCost,
            profit: totalProfit,
            chart: {
                feePer: chartFeePer,
                costPer: chartCostPer,
                profitPer: chartProfitPer
            }
        };
    }

    return (
        <div className="flex flex-col items-center content-center m-3 text-base text-left">
            <ul className="grid w-full grid-cols-1 gap-6 p-4 sm:grid-cols-3 lg:grid-cols-3">
                <li className="col-span-1 divide-y divide-gray-200">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            void form.handleSubmit();
                        }}>
                        <div>
                            <div className="grid min-w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="sellingPrice"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value <= 0 ? t('selling_price_err') : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="flex">
                                                    <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                        {t('selling_price')} ({unit}):
                                                    </label>
                                                    <Tooltip content={<div className="bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                        {t('selling_price_inf')}
                                                    </div>}
                                                        placement="top"
                                                        animate={{
                                                            mount: { scale: 1, y: 0 },
                                                            unmount: { scale: 0, y: 25 },
                                                        }}>
                                                        <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field?.state?.value || ""}
                                                        type="number"
                                                        placeholder={t('selling_price')}
                                                        className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => field.handleChange(e.target.valueAsNumber)}
                                                    />
                                                    {field.state.meta.errors ? (
                                                        <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="cost"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value < 0 ? t('cost_err') : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="flex">
                                                    <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                        {t('cost')} ({unit}):
                                                    </label>
                                                    <Tooltip content={<div className="bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                        {t('cost_inf')}
                                                    </div>}
                                                        placement="top"
                                                        animate={{
                                                            mount: { scale: 1, y: 0 },
                                                            unmount: { scale: 0, y: 25 },
                                                        }}>
                                                        <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <div className="mt-2">
                                                    <input
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field?.state?.value || ""}
                                                        type="number"
                                                        placeholder={t('cost')}
                                                        className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => field.handleChange(e.target.valueAsNumber)}
                                                    />
                                                    {field.state.meta.errors ? (
                                                        <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="offsideAdsFeePercentage"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value < 0 ? '' : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="mt-2">
                                                    <div className="flex">
                                                        <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                            {t('offsite_ads_fee')} (%):
                                                        </label>
                                                        <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                            {t('offsite_ads_fee_inf')}
                                                        </div>}
                                                            placement="top"
                                                            animate={{
                                                                mount: { scale: 1, y: 0 },
                                                                unmount: { scale: 0, y: 25 },
                                                            }}>
                                                            <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                                <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="relative mt-2">
                                                        <PercentDropdown
                                                            id={field.name}
                                                            name={field.name}
                                                            percents={percents}
                                                            value={field?.state?.value ?? 0}
                                                            onBlur={field.handleBlur}
                                                            onChange={(e) => field.handleChange(e.target.value)}
                                                        ></PercentDropdown>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                            </div>
                            <form.Subscribe
                                selector={(state) => [state.canSubmit, state.isSubmitting]}
                                children={([canSubmit, isSubmitting]) => (
                                    <div className="mt-4 sm:col-span-2 sm:col-start-1">
                                        <button type="submit" disabled={!canSubmit}
                                            className="inline-flex px-3 py-1 font-semibold text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
                                        >
                                            {isSubmitting ? "..." : t('calculator')}
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </form>
                </li>

                <li className="col-span-1 divide-y divide-gray-200">
                    <div className="overflow-hidden rounded-lg bg-gray-50">
                        <div className="px-4 py-5 sm:p-6">
                            <ul className="grid grid-cols-1 gap-x-6 gap-y-1 lg:grid-cols-1 xl:gap-x-8">
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('revenue')}</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.revenue).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="text-gray-700">{t('cost')}
                                                <span className="inline-block w-3 h-3 ml-3 bg-gray-400 rounded-full"></span>
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.cost).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('listing_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('listing_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.listingFee).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('transaction_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('transaction_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.transactionFee).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('processing_payment_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('processing_payment_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.paymentProcessingFee).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('offsite_ads_fee_inf_1')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('offsite_ads_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.advertisingFee).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('total_cost')}</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.totalCost).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('profit')}
                                                <span className="inline-block w-3 h-3 ml-3 bg-green-500 rounded-full"></span>
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.profit).format(unit + '0,0.00')}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li className="col-span-1">
                    <div>
                        <ul className="grid grid-cols-1 gap-1 sm:grid-cols-3 sm:gap-1 lg:grid-cols-3">
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('TOTAL_PROFIT')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.profit).format(unit + '0,0.00')}</div>
                            </li>
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('TOTAL_ETSY_FEES')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.totalFee).format(unit + '0,0.00')}</div>
                            </li>
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('PROFIT_MARGIN')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.chart.profitPer).format('0,0.0')}%</div>
                            </li>
                        </ul>
                    </div>

                    <Card>
                        <CardBody className="grid px-2 place-items-center">
                            <Chart {...chartConfig} />
                        </CardBody>
                    </Card>
                </li>
            </ul>
        </div>
    )
}
