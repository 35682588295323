import React, { useState, useEffect } from 'react'
import { useForm } from '@tanstack/react-form'
import config from '../../config.json';
import countryWithPaymentFees from '../../assets/countries.json';
import numeral from 'numeral';
import utils from '../../utils/Utils'
import {
    Card,
    CardBody,
    Tooltip
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import Select from 'react-select'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PercentDropdown from "../../components/controls/PercentDropdown";
import PaymentMethodDropdown from "../../components/controls/PaymentMethodDropdown";

const percents = [
    { id: "percent-0", value: 0, name: '0%' },
    { id: "percent-1", value: 0.15, name: '15%' },
    { id: "percent-2", value: 0.12, name: '12%' }
];

const defaultCountry = {
    "label": "United States",
    "value": "US",
    "currency": "USD",
    "payment_process_fee_per_domestic": 0.03,
    "payment_process_fee_add": 0.25,
    "payment_process_fee_add_currency": "USD"
}

// Function to convert the currency
function convertCurrency(infoCurrency, toCountry, input = 0, from = 'USD') {
    if (toCountry === undefined || toCountry === null || toCountry.currency === undefined || toCountry.currency === null)
        return input;
    var to = toCountry.currency;
    var rate = infoCurrency[to];
    return input * rate;
}

export default function AdvanceContent() {
    const { t } = useTranslation();
    const [unit, setUnit] = useState('USD');
    const orderMethods = [
        { value: 'domestic', label: t('domestic') },
        { value: 'international', label: t('international') },
    ]
    const [showDomesticOrderChoose, setShowDomesticOrderChoose] = useState(false);
    const [resultFees, setResultFees] = useState({
        revenue: 0,
        listingFee: 0,
        transactionFee: 0,
        paymentProcessingFee: 0,
        advertisingFee: 0,
        totalFee: 0,
        cost: 0,
        totalCost: 0,
        profit: 0,
        chart: {
            feePer: 0,
            costPer: 0,
            profitPer: 0
        }
    });
    const [chartConfig, setChartConfig] = useState({
        type: "pie",
        width: 260,
        height: 260,
        series: [],
        options: {
            chart: {
                id: 'basic-pie',
                toolbar: {
                    show: false,
                },
                background: '#fff'
            },
            title: {
                show: "",
            },
            labels: [t('profit'), t('esty_fees'), t('cost')],
            dataLabels: {
                enabled: true,
            },
            colors: ["#16a34a", "#f59e0b", "#a3a3a3"],
            legend: {
                show: false,
                position: 'bottom'
            },
        },
    });
    const [fromCurrency] = useState('USD');
    const [infoCurrency, setInfoCurrency] = useState([]);

    // Calling the api whenever the dependency changes
    useEffect(() => {
        axios.get(`https://open.er-api.com/v6/latest/${fromCurrency}`)
            .then((res) => {
                setInfoCurrency(res.data.rates);
            })
    }, [fromCurrency]);
    const [currCountry, setCurrCountry] = useState(defaultCountry);
    const [orderMethod, setOrderMethod] = useState('domestic');
    const [paymentMethods, setPaymentMethods] = useState([
        { value: 'direct', label: t('direct_checkout') + ' (3% + 0.25 USD)' },
        { value: 'paypal', label: 'Paypal (2.99% + 0.3 USD)' },
    ]);
    const form = useForm({
        defaultValues: {
            sellingPrice: '',
            cost: 0,
            offsideAdsFeePercentage: config.offside_ads_fee_percentage,
            paymentMethod: 'direct',
            country: defaultCountry,
            orderMethod: null,
            quantity: 1
        },
        onChange: (e) => {
            console.log('form-onChange', e);
        },
        onSubmit: async ({ value }) => {
            var result = calculateTotalFees(value)
            var seriesData = [result.chart.profitPer, result.chart.feePer, result.chart.costPer];
            setChartConfig({
                type: "pie",
                width: 260,
                height: 260,
                series: seriesData,
                options: {
                    chart: {
                        id: 'basic-pie',
                        toolbar: {
                            show: false,
                        },
                    },
                    labels: [t('profit'), t('esty_fees'), t('cost')],
                    colors: ["#16a34a", "#f59e0b", "#a3a3a3"],
                    legend: {
                        show: false,
                        position: 'bottom'
                    },
                },
            });
            setResultFees(result);
        },
    })

    //default value quantity = 1
    const calculateTotalFees = (formValue, isPatternFee = false, isShippingFee = false,
        isRegulatoryFee = false, isSubscriptionFee = false, isVAT = false, vatPercent = config.vat_default) => {
        if (formValue === undefined || formValue === null)
            return undefined;
        var costCal = isNaN(formValue.cost) ? 0 : formValue.cost;
        var totalRevenue = formValue.quantity * formValue.sellingPrice;
        var listingFee = convertCurrency(infoCurrency, formValue.country, config.listing_fee * formValue.quantity);
        var transactionFee = config.transaction_fee * totalRevenue;
        //payment processing fee by country and payment method
        var paymentProcessingFee = config.payment_processing_fee * totalRevenue + config.payment_processing_fee_add_more;
        if (formValue.paymentMethod === 'paypal') {
            //check country to convert value payment_processing_fee_add_more_paypal -> currency of country
            var plusFeePaypal = convertCurrency(infoCurrency, formValue.country, config.payment_processing_fee_add_more_paypal);
            paymentProcessingFee = config.payment_processing_fee_paypal * totalRevenue + plusFeePaypal;
        } else {
            if (formValue.country !== null) {
                if (formValue.country.payment_process_fee_per_international && formValue.country.payment_process_fee_per_international !== null) {
                    if (formValue.orderMethod && formValue.orderMethod !== null) {
                        if (formValue.orderMethod.value === 'domestic') {
                            paymentProcessingFee = formValue.country.payment_process_fee_per_domestic * totalRevenue + formValue.country.payment_process_fee_add
                        } else if (formValue.orderMethod.value === 'international') {
                            paymentProcessingFee = formValue.country.payment_process_fee_per_international * totalRevenue + formValue.country.payment_process_fee_add
                        } else {
                            paymentProcessingFee = formValue.country.payment_process_fee_per_domestic * totalRevenue + formValue.country.payment_process_fee_add
                        }
                    } else {
                        paymentProcessingFee = formValue.country.payment_process_fee_per_domestic * totalRevenue + formValue.country.payment_process_fee_add
                    }
                } else {
                    paymentProcessingFee = (formValue.country.payment_process_fee_per_domestic !== undefined ? formValue.country.payment_process_fee_per_domestic : config.payment_processing_fee) * totalRevenue
                        + (formValue.country.payment_process_fee_add !== undefined ? formValue.country.payment_process_fee_add : config.payment_processing_fee_add_more)
                }
            }
        }

        var advertisingFee = formValue.offsideAdsFeePercentage * totalRevenue;
        //optional
        var shippingFee = config.shipping_fee;
        var regulatoryFee = config.regulatory_fee;
        var subscriptionFee = config.subscription_fee;
        var patternFee = config.pattern_fee; // 15USD/month
        var vatDefault = config.vat_default;
        //var vatFee = vatPercent * totalRevenue;
        //total
        var totalFee = listingFee + transactionFee + paymentProcessingFee + advertisingFee;
        if (isPatternFee)
            totalFee += convertCurrency(infoCurrency, formValue.country, patternFee);
        if (isShippingFee)
            totalFee += shippingFee
        if (isRegulatoryFee)
            totalFee += regulatoryFee
        if (isSubscriptionFee)
            totalFee += convertCurrency(infoCurrency, formValue.country, subscriptionFee);
        if (isVAT)
            totalFee += vatDefault

        var totalCost = costCal + totalFee;
        var totalProfit = totalRevenue - totalCost;
        var chartFeePer = utils.roundNumber((totalFee) * 100 / totalRevenue, 1);
        var chartCostPer = utils.roundNumber(costCal * 100 / totalRevenue, 1);
        var chartProfitPer = utils.roundNumber(totalProfit * 100 / totalRevenue, 1);
        return {
            revenue: totalRevenue,
            listingFee: listingFee,
            transactionFee: transactionFee,
            paymentProcessingFee: paymentProcessingFee,
            advertisingFee: advertisingFee,
            totalFee: totalFee,
            cost: costCal,
            totalCost: totalCost,
            profit: totalProfit,
            chart: {
                feePer: chartFeePer,
                costPer: chartCostPer,
                profitPer: chartProfitPer
            }
        };
    }

    return (
        <div className="flex flex-col items-center content-center m-3 text-base text-left">
            <ul className="grid w-full grid-cols-1 gap-6 p-4 sm:grid-cols-3 lg:grid-cols-3">
                <li className="col-span-1 divide-y divide-gray-200">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            void form.handleSubmit();
                        }}>
                        <div>
                            <div className="grid min-w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                                {/* For Selling Price */}
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="sellingPrice"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value <= 0 ? t('selling_price_err') : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="flex">
                                                    <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                        {t('selling_price')} ({unit}):
                                                    </label>
                                                    <Tooltip content={<div className="px-2 py-1 text-white bg-gray-500 rounded shadow-sm">
                                                        {t('selling_price_inf')}
                                                    </div>}
                                                        placement="top"
                                                        animate={{
                                                            mount: { scale: 1, y: 0 },
                                                            unmount: { scale: 0, y: 25 },
                                                        }}>
                                                        <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <div className="mt-1">
                                                    <input
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field?.state?.value || ""}
                                                        type="number"
                                                        placeholder={t('selling_price')}
                                                        className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => field.handleChange(e.target.valueAsNumber)}
                                                    />
                                                    {field.state.meta.errors ? (
                                                        <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                {/* For Cost */}
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="cost"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value < 0 ? t('material_and_cost_err') : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="flex">
                                                    <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                        {t('material_and_cost')} ({unit}):
                                                    </label>
                                                    <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                        {t('material_and_cost_inf')}
                                                    </div>}
                                                        placement="top"
                                                        animate={{
                                                            mount: { scale: 1, y: 0 },
                                                            unmount: { scale: 0, y: 25 },
                                                        }}>
                                                        <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <div className="mt-1">
                                                    <input
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field?.state?.value || ""}
                                                        type="number"
                                                        placeholder={t('material_and_cost')}
                                                        className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => field.handleChange(e.target.valueAsNumber)}
                                                    />
                                                    {field.state.meta.errors ? (
                                                        <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                {/* For Ads */}
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="offsideAdsFeePercentage"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value < 0 ? '' : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="mt-1">
                                                    <div className="flex">
                                                        <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                            {t('offsite_ads_fee')} (%):
                                                        </label>
                                                        <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                            {t('offsite_ads_fee_inf')}
                                                        </div>}
                                                            placement="top"
                                                            animate={{
                                                                mount: { scale: 1, y: 0 },
                                                                unmount: { scale: 0, y: 25 },
                                                            }}>
                                                            <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                                <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="relative mt-1">
                                                        <PercentDropdown
                                                            id={field.name}
                                                            name={field.name}
                                                            percents={percents}
                                                            value={field?.state?.value ?? 0}
                                                            onBlur={field.handleBlur}
                                                            onChange={(e) => field.handleChange(e.target.value)}
                                                        ></PercentDropdown>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                {/* For Payment methods */}
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="paymentMethod"
                                        validators={{
                                            onChange: ({ value }) =>
                                                value === '' ? t('payment_method_err') : undefined,
                                        }}
                                    >
                                        {(field) => (
                                            <>
                                                <div className="mt-1">
                                                    <div className="flex">
                                                        <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                            {t('payment_method')}:
                                                        </label>
                                                        <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                            {t('payment_method_inf')}
                                                        </div>}
                                                            placement="top"
                                                            animate={{
                                                                mount: { scale: 1, y: 0 },
                                                                unmount: { scale: 0, y: 25 },
                                                            }}>
                                                            <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                                <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="relative mt-1">
                                                        <PaymentMethodDropdown
                                                            paymentMethods={paymentMethods}
                                                            id={field.name}
                                                            name={field.name}
                                                            value={field?.state?.value ?? ''}
                                                            onBlur={field.handleBlur}
                                                            onChange={(e) => field.handleChange(e.target.value)}
                                                        ></PaymentMethodDropdown>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                {/* For Country */}
                                <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                    <form.Field
                                        name="country"
                                    >
                                        {(field) => (
                                            <>
                                                <div className="flex">
                                                    <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                        {t('country_seller')}:
                                                    </label>
                                                    <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                        {t('country_seller_inf')}:
                                                    </div>}
                                                        placement="top"
                                                        animate={{
                                                            mount: { scale: 1, y: 0 },
                                                            unmount: { scale: 0, y: 25 },
                                                        }}>
                                                        <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                            <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <div className="mt-1">
                                                    <Select
                                                        id={field.name}
                                                        name={field.name}
                                                        value={field.state?.value || defaultCountry}
                                                        options={countryWithPaymentFees}
                                                        defaultValue={defaultCountry}
                                                        menuPlacement="top"
                                                        menuPosition='fixed'
                                                        onChange={(e) => {
                                                            field.handleChange(e);
                                                            setCurrCountry(e);
                                                            var percent = 0.03;
                                                            var addplus = 0.25;
                                                            var unitTmp = 'USD';
                                                            if (e !== null) {
                                                                if (e.currency !== undefined && e.currency !== null)
                                                                    unitTmp = e.currency
                                                                else
                                                                    unitTmp = 'USD'
                                                                if (e.payment_process_fee_per_domestic !== null && e.payment_process_fee_per_domestic !== undefined
                                                                    && e.payment_process_fee_per_international !== null && e.payment_process_fee_per_international !== undefined)
                                                                    setShowDomesticOrderChoose(true);
                                                                else {
                                                                    setShowDomesticOrderChoose(false);
                                                                    setOrderMethod('domestic');
                                                                }
                                                                if (orderMethod === 'domestic' && e.payment_process_fee_per_domestic !== undefined) {
                                                                    percent = e.payment_process_fee_per_domestic;
                                                                    addplus = e.payment_process_fee_add;
                                                                    unitTmp = e.payment_process_fee_add_currency;
                                                                } else if (orderMethod === 'international' && e.payment_process_fee_per_international !== undefined) {
                                                                    percent = e.payment_process_fee_per_international;
                                                                    addplus = e.payment_process_fee_add;
                                                                    unitTmp = e.payment_process_fee_add_currency;
                                                                } else {
                                                                    percent = e.payment_process_fee_per_domestic !== undefined ? e.payment_process_fee_per_domestic : 0.03;
                                                                    addplus = e.payment_process_fee_add !== undefined ? e.payment_process_fee_add : 0.25;
                                                                    unitTmp = e.payment_process_fee_add_currency !== undefined ? e.payment_process_fee_add_currency : 'USD';
                                                                }
                                                            } else {
                                                                percent = 0.03;
                                                                addplus = 0.25;
                                                                unitTmp = 'USD'
                                                            }
                                                            setUnit(unitTmp);
                                                            var labelTmp = t('direct_checkout') + ' (' + percent * 100 + '% + ' + addplus + ' ' + unitTmp + ')';
                                                            setPaymentMethods([
                                                                { value: 'direct', label: labelTmp },
                                                                { value: 'paypal', label: 'Paypal (2.99% + 0.3 USD)' },
                                                            ]);
                                                        }}
                                                        className="z-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                    />
                                                    {field.state.meta.errors ? (
                                                        <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                    </form.Field>
                                </div>
                                {showDomesticOrderChoose && (
                                    <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                        <form.Field
                                            name="orderMethod"
                                        >
                                            {(field) => (
                                                <>
                                                    <div className="flex">
                                                        <label htmlFor={field.name} className="block text-sm font-medium leading-6 text-gray-700">
                                                            {t('orders_method')}:
                                                        </label>
                                                        <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                            {t('orders_method_inf')}
                                                        </div>}
                                                            placement="top"
                                                            animate={{
                                                                mount: { scale: 1, y: 0 },
                                                                unmount: { scale: 0, y: 25 },
                                                            }}>
                                                            <svg data-tooltip-target="tooltip-sell-price" data-tooltip-trigger="hover" style={{ cursor: 'pointer' }}
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#d1d5db" className="w-4 h0">
                                                                <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9Z" clipRule="evenodd" />
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="mt-1">
                                                        <Select
                                                            id={field.name}
                                                            name={field.name}
                                                            value={field?.state?.value || orderMethods[0]}
                                                            options={orderMethods}
                                                            defaultValue={orderMethods[0]}
                                                            menuShouldScrollIntoView={true}
                                                            onChange={(e) => {
                                                                field.handleChange(e);
                                                                setOrderMethod(e.value);
                                                                var percent = 0.03;
                                                                var addplus = 0.25;
                                                                var unitTmp = 'USD'
                                                                if (currCountry !== undefined && currCountry !== null) {
                                                                    if (e.value === 'domestic' && currCountry.payment_process_fee_per_domestic !== undefined) {
                                                                        percent = currCountry.payment_process_fee_per_domestic;
                                                                        addplus = currCountry.payment_process_fee_add;
                                                                        unitTmp = currCountry.payment_process_fee_add_currency;
                                                                    } else if (e.value === 'international' && currCountry.payment_process_fee_per_international !== undefined) {
                                                                        percent = currCountry.payment_process_fee_per_international;
                                                                        addplus = currCountry.payment_process_fee_add;
                                                                        unitTmp = currCountry.payment_process_fee_add_currency;
                                                                    } else {
                                                                        percent = currCountry.payment_process_fee_per_domestic;
                                                                        addplus = currCountry.payment_process_fee_add;
                                                                        unitTmp = currCountry.payment_process_fee_add_currency;
                                                                    }
                                                                    if (currCountry.currency !== undefined)
                                                                        unitTmp = currCountry.currency;
                                                                } else {
                                                                    unitTmp = 'USD';
                                                                }
                                                                var labelTmp = t('direct_checkout') + ' (' + percent * 100 + '% + ' + addplus + ' ' + unitTmp + ')';
                                                                setPaymentMethods([
                                                                    { value: 'direct', label: labelTmp },
                                                                    { value: 'paypal', label: 'Paypal (2.99% + 0.3 USD)' },
                                                                ]);
                                                            }}
                                                            className="text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                                                        />
                                                        {field.state.meta.errors ? (
                                                            <em className="block text-xs italic text-red-500" role="alert">{field.state.meta.errors.join(', ')}</em>
                                                        ) : null}
                                                    </div>
                                                </>
                                            )}
                                        </form.Field>
                                    </div>
                                )}
                            </div>
                            <form.Subscribe
                                selector={(state) => [state.canSubmit, state.isSubmitting]}
                                children={([canSubmit, isSubmitting]) => (
                                    <div className="mt-4 sm:col-span-2 sm:col-start-1">
                                        <button type="submit" disabled={!canSubmit}
                                            className="inline-flex px-3 py-1 font-semibold text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
                                        >{isSubmitting ? "..." : t('calculator')}
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </form>
                </li>

                <li className="col-span-1 divide-y divide-gray-200">
                    <div className="overflow-hidden rounded-lg bg-gray-50">
                        <div className="px-4 py-5 sm:p-6">
                            <ul className="grid grid-cols-1 gap-x-6 gap-y-1 lg:grid-cols-1 xl:gap-x-8">
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('revenue')}</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.revenue).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="text-gray-700">{t('cost')}
                                                <span className="inline-block w-3 h-3 ml-3 bg-gray-400 rounded-full"></span>
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.cost).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('listing_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('listing_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.listingFee).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('transaction_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('transaction_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.transactionFee).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('processing_payment_fee_inf')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('processing_payment_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.paymentProcessingFee).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <Tooltip content={<div className="w-96 bg-gray-500 rounded px-2 py-1 text-white shadow-sm">
                                                {t('offsite_ads_fee_inf_1')}
                                            </div>}
                                                placement="top"
                                                animate={{
                                                    mount: { scale: 1, y: 0 },
                                                    unmount: { scale: 0, y: 25 },
                                                }}>
                                                <dt className="text-gray-700">{t('offsite_ads_fee')}
                                                    <span className="inline-block w-3 h-3 ml-3 rounded-full bg-amber-500"></span>
                                                </dt>
                                            </Tooltip>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.advertisingFee).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('total_cost')}</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.totalCost).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                                <li className="w-full overflow-hidden">
                                    <dl className="text-sm leading-6">
                                        <div className="flex justify-between gap-x-4">
                                            <dt className="font-medium leading-6 text-gray-900">{t('profit')}
                                                <span className="inline-block w-3 h-3 ml-3 bg-green-500 rounded-full"></span>
                                            </dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="px-2 py-1 text-sm font-medium text-green-700 rounded-md ring-1 ring-inset bg-green-50 ring-green-600/20">
                                                    {numeral(resultFees.profit).format('0,0.00')} <span className='text-xs'>{unit}</span>
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li className="col-span-1">
                    <div>
                        <ul className="grid grid-cols-1 gap-1 sm:grid-cols-3 sm:gap-1 lg:grid-cols-3">
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('TOTAL_PROFIT')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.profit).format('0,0.00')} <span className='text-xs'>{unit}</span></div>
                            </li>
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('TOTAL_ETSY_FEES')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.totalFee).format('0,0.00')} <span className='text-xs'>{unit}</span></div>
                            </li>
                            <li className="text-center">
                                <span className="text-xs text-gray-400">{t('PROFIT_MARGIN')}</span>
                                <div className="font-mono text-base text-black-600">{numeral(resultFees.chart.profitPer).format('0,0.0')}%</div>
                            </li>
                        </ul>
                    </div>

                    <Card>
                        <CardBody className="grid px-2 place-items-center">
                            <Chart {...chartConfig} />
                        </CardBody>
                    </Card>
                </li>
            </ul>
        </div >
    )
}
