import { createRoute } from "@tanstack/react-router";
import { rootRoute } from "../..";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from "../../components/controls/Tabs";
import StandardContent from "./StandardContent";
import AdvanceContent from "./AdvanceContent"
import FQAContent from "./FQAContent";


export const feeCalculatorRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: FeeCalculator
})

function FeeCalculator() {
    const { t } = useTranslation();
    return (
        <main>
            <div className="px-4 pt-8 pb-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <section>
                    <div className="text-center mx-auto md:text-center md:max-w-2xl">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {t('name_app')}
                        </h1>
                        <p className="mt-1 text-lg tracking-tight text-gray-700">
                            {t('simplify_your_selling_costs_in')} {new Date().getFullYear()}
                        </p>
                        <p className="mt-1 tracking-tight text-gray-700">
                            {t('created_by')}
                            <a href="https://heyetsy.com" className="font-semibold text-blue-500 hover:text-blue-600"> HeyEtsy.com</a>
                        </p>
                    </div>
                    <p className="max-w-3xl mx-auto mt-4 tracking-tight text-center text-gray-700">
                        {t('main_descriptions')}
                    </p>
                    {/* UI Tab */}
                    <div className="w-full max-w-sm p-8 mx-auto mt-4 overflow-hidden rounded shadow-lg lg:max-w-full lg:flex bg-white/5 ring-gray-200 ring-1 xl:p-6">
                        <div className="w-full">
                            <Tabs>
                                <Tab label="standard" displayName={t('standard')}>
                                    <StandardContent />
                                </Tab>
                                <Tab label="advance" displayName={t('advance')}>
                                    <AdvanceContent />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
                <FQAContent />
            </div>
        </main>
    )
}

export default FeeCalculator;
