import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

function LanguageSelector({ lngs, i18n, t }) {
  const [selected, setSelected] = useState();

  const handleChange = (event) => {
    setSelected(event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div className=''>
        <select
          id='language-selector'
          name='language-selector'
          value={selected}
          onChange={handleChange}
          className="flex-auto text-sm font-semibold rounded-md border-0 pl-2 pr-7 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500">
          {Object.keys(lngs).map((lng) => (
            <option key={lng} value={lng}>
              {lngs[lng].nativeName}
            </option>
          ))}
        </select>
      </div>
  );
}

export default withTranslation()(LanguageSelector);