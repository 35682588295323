import React, { useState } from 'react';

const PaymentMethodDropdown = ({ paymentMethods, id, name, onChange}) => {
    const [selected, setSelected] = useState();

    const handleChange = (event) => {
        setSelected(event.target.value);
        onChange(event);
    };

    return (
        <select
            id={id}
            name={name}
            value={selected}
            onChange={handleChange}
            className="min-w-full flex-auto rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
        >
            {paymentMethods.map((payment) => (
                <option key={payment.value} value={payment.value}>
                    {payment.label}
                </option>
            ))}
        </select>
    );
};

export default PaymentMethodDropdown;