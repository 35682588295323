import React, { useState } from "react";
import fqasEn from '../../assets/fqa-en.json';
import fqasVi from '../../assets/fqa-vi.json';
import { useTranslation } from 'react-i18next';

const FQAContent = () => {
  let fqas = [];
  const { t, i18n } = useTranslation();
  if (i18n.language === "en")
    fqas = fqasEn;
  else if (i18n.language === "vi")
    fqas = fqasVi;
  const leftFQA = fqas ? fqas.filter(item => item.col === 0) : [];
  const rightFQA = fqas ? fqas.filter(item => item.col === 1) : [];
  return (
    <section className="px-4 relative z-20 overflow-hidden pb-2 pt-5 dark:bg-dark lg:pb-[60px] lg:pt-[90px]">
      <div className="container mx-auto">
        <div className="-mx-2 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-10">
              <span className="mb-2 block text-lg font-semibold text-primary">
                {t('fqa_title')}
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                {t('fqa_any_question')}
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                {t('fqa_sub_title')}
              </p>
              <p className="text-base text-body-color dark:text-dark-6">
                {t('fqa_sub_title_1')}
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-2 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            {leftFQA.map((fqa0, index) => (
              <FAQItem key={'0' + index}
                header={fqa0.header}
                text={fqa0.text}
              />
            ))}
          </div>
          <div className="w-full px-4 lg:w-1/2">
            {rightFQA.map((fqa1, index) => (
              <FAQItem key={'1' + index}
                header={fqa1.header}
                text={fqa1.text}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.36" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
              <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default FQAContent;

// Function to parse HTML string into React elements
const parseHTML = (htmlString) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};


const FAQItem = ({ header, text }) => {
  const [active, setActive] = useState(true);

  const handleToggle = () => {
    setActive(!active);
  };
  return (
    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-4 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${active ? "rotate-180" : ""}`}
            width="17"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill=""
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
            {header}
          </h4>
        </div>
      </button>

      <div className={`pl-[42px] duration-200 ease-in-out ${active ? "block" : "hidden"}`}>
        <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
          {parseHTML(text)}
        </div>
      </div>
    </div>
  );
};