import React from 'react'
import Logo from '../logo.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Loader = () => {
    return (
        <>
            <nav x-data="{ open: false }" className="bg-white border-b border-gray-100">
                <div className="px-4 py-1 mx-auto">
                    <div className="flex justify-between md:h-16">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center flex-shrink-0">
                                <a href="/">
                                    <img className="block w-auto h-10 text-gray-300 fill-current"
                                        src={Logo}
                                        alt="YTuong.me" />
                                </a>
                            </div>
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <div className="flex items-center space-x-4">
                                    <a href="https://go.ytuong.dev/create-a-new-heyetsy-account" rel="noreferrer" target="_blank" className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300">
                                        <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                                        </svg>
                                        <span>Create account</span>
                                    </a>
                                    <a href="https://heyetsy.com/login" className="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-700">Log in
                                        <span aria-hidden="true">→</span></a>
                                    <div className=''>
                                        <select
                                            id='language-selector'
                                            name='language-selector'
                                            className="flex-auto text-sm font-semibold rounded-md border-0 pl-2 pr-7 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500">
                                            <option key='en' value='en'>
                                                English
                                            </option>
                                            <option key='vi' value='vi'>
                                                Tiếng Việt
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center -mr-2 sm:hidden">
                            <button className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                                <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:hidden">
                    <div className="pb-1 border-t border-gray-200">
                        <div className="items-center justify-between px-4 py-3">
                            <a href="https://go.ytuong.dev/create-a-new-heyetsy-account" rel="noreferrer" target="_blank" className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300">
                                <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                                </svg>
                                <span>Create account</span>
                            </a>
                            <a href="https://heyetsy.com/login" className="block mt-3 text-base font-medium text-gray-500 hover:text-gray-700 hover:underline">
                                Log in <span aria-hidden="true">→</span></a>
                            <div className=''>
                                <select
                                    id='language-selector'
                                    name='language-selector'
                                    className="flex-auto text-sm font-semibold rounded-md border-0 pl-2 pr-7 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500">
                                    <option key='en' value='en'>
                                        English
                                    </option>
                                    <option key='vi' value='vi'>
                                        Tiếng Việt
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <main>
                <div className="px-4 pt-8 pb-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <section>
                        <div className="text-center mx-auto md:text-center md:max-w-2xl">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                {<Skeleton />}
                            </h1>
                            <p className="mt-1 text-lg tracking-tight text-gray-700">
                                {<Skeleton />}
                            </p>
                            <p className="mt-1 tracking-tight text-gray-700">
                                {<Skeleton />}
                            </p>
                        </div>
                        <p className="max-w-3xl mx-auto mt-4 tracking-tight text-center text-gray-700">
                            {<Skeleton />}
                        </p>
                        <div className="w-full max-w-sm p-8 mx-auto mt-4 overflow-hidden rounded shadow-lg lg:max-w-full lg:flex bg-white/5 ring-gray-200 ring-1 xl:p-6">
                            <div className="w-full">
                                <div className="mx-auto">
                                    <div className="flex max-w-md mx-auto border-b border-gray-300">
                                        <button
                                            className='flex-1 text-gray-700 font-medium py-2'>
                                            {<Skeleton height={46} />}
                                        </button>
                                    </div>
                                    <div className="py-4">
                                        <div className="flex flex-col items-center content-center m-3 text-base text-left">
                                            <ul className="grid w-full grid-cols-1 gap-6 p-4 sm:grid-cols-3 lg:grid-cols-3">
                                                <li className="col-span-1 divide-y divide-gray-200">
                                                    <div>
                                                        <div className="grid min-w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                                                            <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                                                <div className="mt-2">
                                                                    <div className="mt-2">
                                                                        <Skeleton />
                                                                    </div>
                                                                    <Skeleton />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                                                <div className="mt-2">
                                                                    <div className="mt-2">
                                                                        <Skeleton />
                                                                    </div>
                                                                    <Skeleton />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 mr-8 sm:col-span-2 sm:col-start-1">
                                                                <div className="mt-2">
                                                                    <div className="mt-2">
                                                                        <Skeleton />
                                                                    </div>
                                                                    <Skeleton />
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 sm:col-span-2 sm:col-start-1">
                                                                <button className="inline-flex px-3 py-1 font-semibold text-white border border-transparent rounded-md shadow-sm"
                                                                >
                                                                    <Skeleton height={26} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="col-span-1 divide-y divide-gray-200">
                                                    <div className="overflow-hidden rounded-lg bg-gray-50">
                                                        <div className="px-4 py-5 sm:p-6">
                                                            <ul className="grid grid-cols-1 gap-x-6 gap-y-1 lg:grid-cols-1 xl:gap-x-8">
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                                <li className="w-full overflow-hidden">
                                                                    <dl className="text-sm leading-6">
                                                                        <div className="flex justify-between gap-x-4">
                                                                            <dt className="font-medium leading-6 text-gray-900">
                                                                                <Skeleton />
                                                                            </dt>
                                                                            <dd className="flex items-start gap-x-2">
                                                                                <Skeleton />
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className="col-span-1">
                                                    <div>
                                                        <ul className="grid grid-cols-1 gap-1 sm:grid-cols-3 sm:gap-1 lg:grid-cols-3">
                                                            <li className="text-center">
                                                                <span className="text-xs text-gray-400">
                                                                    <Skeleton />
                                                                </span>
                                                                <div className="font-mono text-base text-black-600">
                                                                    <Skeleton />
                                                                </div>
                                                            </li>
                                                            <li className="text-center">
                                                                <span className="text-xs text-gray-400">
                                                                    <Skeleton />
                                                                </span>
                                                                <div className="font-mono text-base text-black-600">
                                                                    <Skeleton />
                                                                </div>
                                                            </li>
                                                            <li className="text-center">
                                                                <span className="text-xs text-gray-400">
                                                                    <Skeleton />
                                                                </span>
                                                                <div className="font-mono text-base text-black-600">
                                                                    <Skeleton />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </section >

                    <section className="px-4 relative z-20 overflow-hidden pb-2 pt-5 dark:bg-dark lg:pb-[60px] lg:pt-[90px]">
                        <div className="container mx-auto">
                            <div className="-mx-2 flex flex-wrap">
                                <div className="w-full px-4">
                                    <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-10">
                                        <span className="mb-2 block text-lg font-semibold text-primary">
                                            <Skeleton height={26} />
                                        </span>
                                        <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                                            <Skeleton height={46} />
                                        </h2>
                                        <p className="text-base text-body-color dark:text-dark-6">
                                            <Skeleton />
                                        </p>
                                        <p className="text-base text-body-color dark:text-dark-6">
                                            <Skeleton />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="-mx-2 flex flex-wrap">
                                <div className="w-full px-4 lg:w-1/2">
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full px-4 lg:w-1/2">
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-4 lg:px-5 xl:px-6">
                                        <div className="w-full">
                                            <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
                                                <Skeleton height={28} />
                                            </h4>
                                        </div>
                                        <div className='pl-[42px] duration-200 ease-in-out'>
                                            <div className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                                                <Skeleton count={3} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </main >
            <footer aria-labelledby="footer-heading">
                <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="pt-8 border-t border-gray-200">
                        <p className="text-base text-gray-700 xl:text-center">
                            <Skeleton />
                        </p>
                        <p className="text-base text-gray-700 xl:text-center">
                            <Skeleton />
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

// Skeleton Loader
export default Loader
