import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import {
  RouterProvider,
  createRouter,
  createRootRoute
} from '@tanstack/react-router';

import './index.css';
import App from './App';
import { feeCalculatorRoute } from './pages/feecalculator/FeeCalculator';
import { error404Route } from './pages/error404';
import './i18n';


export const rootRoute = createRootRoute({
  component: App
})

const routeTree = rootRoute.addChildren([feeCalculatorRoute, error404Route])

const router = createRouter({ routeTree })

const rootElement = document.getElementById('root')
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RouterProvider router={router}>
      </RouterProvider>
    </StrictMode>
  )
}
