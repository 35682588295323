import './App.css';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { Outlet } from '@tanstack/react-router'
import { Suspense } from 'react';

import NavigationHeader from './components/layout/NavigationHeader'
import Footer from './components/layout/Footer'
import Loader from './components/Loader'

function App() {
  const { t, i18n } = useTranslation(['translation', 'common']);

  return (
    <>
      <NavigationHeader t={t} i18n={i18n} />
      <I18nextProvider i18n={i18n}>
        <Outlet />
      </I18nextProvider>
      <Footer t={t} />
    </>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  );
}
