import Logo from '../../logo.png'
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../controls/LanguageSelector'

const lngs = {
    en: { nativeName: 'English' },
    vi: { nativeName: 'Tiếng Việt' }
};

function NavigationHeader({ t, i18n }) {
    return (
        <>
            <nav x-data="{ open: false }" className="bg-white border-b border-gray-100">
                <div className="px-4 py-1 mx-auto">
                    <div className="flex justify-between md:h-16">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center flex-shrink-0">
                                <a href="/">
                                    <img className="block w-auto h-10 text-gray-600 fill-current"
                                        src={Logo}
                                        alt="YTuong.me" />
                                </a>
                            </div>
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <div className="flex items-center space-x-4">
                                    <a href="https://go.ytuong.dev/create-a-new-heyetsy-account" rel="noreferrer" target="_blank" className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                        <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                                        </svg>
                                        <span>{t('header.create_account')}</span>
                                    </a>
                                    <a href="https://heyetsy.com/login" className="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-700">{t('header.login')}
                                        <span aria-hidden="true">→</span></a>
                                    <LanguageSelector
                                        lngs={lngs}
                                        i18n={i18n}
                                        t={t} />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center -mr-2 sm:hidden">
                            <button className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                                <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:hidden">
                    <div className="pb-1 border-t border-gray-200">
                        <div className="items-center justify-between px-4 py-3">
                            <a href="https://go.ytuong.dev/create-a-new-heyetsy-account" rel="noreferrer" target="_blank" className="relative inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                                </svg>
                                <span>{t('header.create_account')}</span>
                            </a>
                            <a href="https://heyetsy.com/login" className="block mt-3 text-base font-medium text-gray-500 hover:text-gray-700 hover:underline">
                                {t('header.login')} <span aria-hidden="true">→</span></a>
                            <LanguageSelector
                                lngs={lngs}
                                i18n={i18n}
                                t={t} />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default withTranslation()(NavigationHeader);
