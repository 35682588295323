import React from 'react';
import { withTranslation } from 'react-i18next';

function Footer({ t }) {
    return (
        <>
            <footer aria-labelledby="footer-heading">
                <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="pt-8 border-t border-gray-200">
                        <p className="text-base text-gray-700 xl:text-center">
                            © {new Date().getFullYear()} { t('footer.copyright') }
                        </p>
                        <p className="text-base text-gray-700 xl:text-center">
                            { t('footer.end_footer') }
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default withTranslation()(Footer);